<template>
  <div>
    <b-row class="justify-content-center my-3">
      <b-button-group>
        <b-button class="no-focus-outline" :variant="selectedPhoneType === 'iphone' ? 'dark' : 'light'" @click="togglePhoneType">
          iPhone
        </b-button>
        <b-button class="no-focus-outline" :variant="selectedPhoneType === 'android' ? 'dark' : 'light'" @click="togglePhoneType">
          Android
        </b-button>
      </b-button-group>
    </b-row>
    <b-row>
      <b-col align-self="center" class="d-flex justify-content-center">
        <b-link v-if="mobileView" :href="linkByPhoneType()">
          <b-img :src="`/images/${storeIcon()}`" />
        </b-link>
        <vue-qrcode v-else :value="linkByPhoneType()" :options="{ width: 150 }" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'

export default {
  name: 'VirtualPhoneQrCodes',
  components: { VueQrcode },
  props: {
    mobileView: {
      type: Boolean,
      default: false,
    },
  },
  data() { 
    return {
      selectedPhoneType: "iphone",
      androidPhoneTokenLink: 'https://play.google.com/store/apps/details?id=com.corporatetools.phoneservice',
      iOSPhoneTokenLink: 'https://apps.apple.com/us/app/corporate-phone/id1593053186',
    }
  },
  computed: {

  },
  methods: {
    togglePhoneType() {
      this.selectedPhoneType = this.selectedPhoneType === "iphone" ? "android" : "iphone"
    },
    linkByPhoneType() {
      return this.selectedPhoneType === "iphone" ? this.iOSPhoneTokenLink : this.androidPhoneTokenLink
    },
    storeIcon() {
      return this.selectedPhoneType === "iphone" ? "iosStore.png" : "googleStore.png"
    },
  },
}
</script>