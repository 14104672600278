var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "justify-content-center my-3" },
        [
          _c(
            "b-button-group",
            [
              _c(
                "b-button",
                {
                  staticClass: "no-focus-outline",
                  attrs: {
                    variant:
                      _vm.selectedPhoneType === "iphone" ? "dark" : "light",
                  },
                  on: { click: _vm.togglePhoneType },
                },
                [_vm._v("\n        iPhone\n      ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "no-focus-outline",
                  attrs: {
                    variant:
                      _vm.selectedPhoneType === "android" ? "dark" : "light",
                  },
                  on: { click: _vm.togglePhoneType },
                },
                [_vm._v("\n        Android\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-center",
              attrs: { "align-self": "center" },
            },
            [
              _vm.mobileView
                ? _c(
                    "b-link",
                    { attrs: { href: _vm.linkByPhoneType() } },
                    [
                      _c("b-img", {
                        attrs: { src: `/images/${_vm.storeIcon()}` },
                      }),
                    ],
                    1
                  )
                : _c("vue-qrcode", {
                    attrs: {
                      value: _vm.linkByPhoneType(),
                      options: { width: 150 },
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }